import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "../../../App.scss";
import "../../../themes/template3.scss";

import Slider2 from "../../slider2";
import SimpleListWithTitle from "../../simpleListWithTitle/simpleListWithTitle";
import CardWithImage from "../../cardWithImage/cardWithImage";
import VideoPlayer from "../../videoPlayer";
import Showcase from "../../showcase/showcase";
import { useLocation } from "../../../hooks/useLocation";
import { setSecurityParameters } from "../../../services/httpService";

import amplifyLogo from "./assets/images/amplify_logo.png";
import salesXLogo from "./assets/images/Powered By_sales-xceleration-logo-h.png";

import salesXLogoWhite from "./assets/images/Powered By_sales-xceleration-logo-w.png";

import SalesStrategyImage from "./assets/images/Sales-Xceleration-CSOMS-Sales-Strategy-2.png";
import SalesInfrastrutureImage from "./assets/images/Sales-Xceleration-CSOMS-Sales-Infrastructure.png";
import SalesManagementImage from "./assets/images/Sales-Xceleration-CSOMS-Sales-Management.png";
import SalesTeamImage from "./assets/images/SalesXceleration-CSOMS-Sales-Team.png";

//
import CSLLogo from "./assets/images/CSL_SX_Logo.png";
import CLSLogoCanada from "./assets/images/ASM_Canada Logo.png";
import assessmentAreaImage from "./assets/images/Services_Graphic.png";
import recruitingServiceImage from "./assets/images/Assessment_area_image.png";

import { useTracking } from "react-tracking";
import { AttributeType } from "../../../utils/constants2";

import {
  getAttribute,
  getBlogs,
  getTestimonials,
  getVideos,
  getEvents,
  getOptionalServices,
} from "./transformations";

import "./salesx.scss";
import TestimonialsCarousel from "../../testimonialsCarousel/testimonialsCarousel";
import { Modal } from "react-bootstrap";
import FormLead from "../../formLead";
import Events from "../../events/events";
import { SEARCH_RES_LOG_TYPE, EVENT_LOG_TYPE } from "../../../utils/constants";
import BullseyeLoader from "../../bullseyeLoader/bullseyeLoader";

const homepage = process.env.REACT_APP_VIRTUAL_PATH;

//import { testimonials } from "../src/services/json/sampledata";

const salesGrowthInformations = [
  {
    img: `${SalesStrategyImage}`,
    title: "<h3>SALES <br> STRATEGY</h3>",
    body: "Our proven Sales System guides sales teams in creating key sales components for better focus, alignment, and performance.",
  },
  {
    img: `${SalesInfrastrutureImage}`,
    title: "<h3>SALES <br> INFRASTRUCTURE</h3>",
    body: "We focus on building a solid foundation for growth by implementing proven sales processes and equipping your team with the right tools.",
  },
  {
    img: `${SalesManagementImage}`,
    title: "<h3>SALES <br> MANAGEMENT</h3>",
    body: "As seasoned sales leaders, we manage and mentor your team while supporting processes and tools are established and adopted.",
  },
  {
    img: `${SalesTeamImage}`,
    title: "<h3>SALES <br> TEAM</h3>",
    body: "Our comprehensive assessment identifies skill and personnel gaps within your team, followed by an action plan for enhancement.",
  },
];

setSecurityParameters(
  process.env.REACT_APP_CLIENT_ID,
  process.env.REACT_APP_API_KEY
);

const getLocationInformation = (location) => {
  let locationInformation = <></>;

  if (location) {
    locationInformation = (
      <div>
        <div className="bl-text-bold-500 h5 listMapLocator__leadModal--title">
          {location.Name}
        </div>

        <div className="listMapLocator__leadModal-locationAddress">{`
            ${location.City ? location.City + "," : ""}
            ${location.StateName || ""} 
            ${location.PostCode || ""}`}</div>
      </div>
    );
  }

  return locationInformation;
};

function SalesXPage() {
  const [trackingCode, setTrackingCode] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const searchLogId = searchParams.get("searchlogid");

  const { location } = useParams(); // props.match.params.interfaceName;

  const [locationData] = useLocation(
    location,
    null, // ThirdpartyId
    process.env.REACT_APP_LOCAL_PAGE_ID
  );

  const [colorSchema, setColorSchema] = useState({
    fontColor: { color: "#C2002F" },
    buttonColors: {
      borderColor: "#ac0415",
      backgroundColor: "#C2002F",
      color: "#fff",
    },
  });

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  function ShowModal(formId) {
    setformIdModal(formId);
    setShowLeadForm(true);
  }

  const [showLeadForm, setShowLeadForm] = useState(false);

  const handleCloseLeadForm = () => {
    setShowLeadForm(false);
  };
  const [caseStudies, setCaseStudies] = useState([]);
  const [salesBlog, setSalesBlog] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [videos, setVideos] = useState([]);
  const [formIdModal, setformIdModal] = useState("");
  const [events, setEvent] = useState([]);

  const [TitleName, setTitleName] = useState("");

  const documentIcon = (
    <svg role="img" className="mr-2" width="16" height="16">
      <use
        xlinkHref={`${homepage}images/b-icons/bootstrap-icons.svg#file-earmark-text-fill`}
        style={{ ...colorSchema.fontColor }}
      />
    </svg>
  );

  const [locationId, setLocationId] = useState(null);
  const [locationName, setLocationName] = useState(null);
  const [searchReqLogId, setSearchLogReqId] = useState(searchLogId);

  const { Track, trackEvent } = useTracking({
    locationId,
    locationName,
    searchReqLogId,
  });

  useEffect(() => {
    if (locationData.loading) return;

    setCaseStudies(
      locationData.location.Attributes.filter((x) =>
        x.AttributeName.toLowerCase().includes("case study")
      ).map((x) => {
        return {
          id: x.AttributeId,
          title: x.AttributeDisplayText,
          url: x.AttributeValue,
        };
      })
    );

    setVideos(
      locationData.location.Attributes.filter((x) =>
        x.AttributeName.toLowerCase().includes("video")
      ).map((x) => {
        return {
          id: x.AttributeId,
          videourl: x.AttributeValue,
        };
      })
    );

    setSalesBlog(getBlogs(locationData.location.ComplexAttributes));
    setTestimonials(getTestimonials(locationData.location.Attributes));

    setEvent(getEvents(locationData.location.Events));

    const isRed = !!locationData.location.Attributes.find(
      (x) =>
        x.AttributeName.toLowerCase().includes("layout options") &&
        x.AttributeValue.toLowerCase().includes("red")
    );

    if (!isRed) {
      // Get a copy of the current state
      const updatedColorSchema = { ...colorSchema };

      // Update the backgroundColor property
      updatedColorSchema.buttonColors.backgroundColor = "#004877";
      updatedColorSchema.buttonColors.borderColor = "#004877";
      updatedColorSchema.fontColor.color = "#004877";

      // Set the state to the updated value
      setColorSchema(updatedColorSchema);
    }

    setTitleName(locationData.location.Name.split(" - ")[0]);

    setLocationId(locationData.location.Id);
    setLocationName(locationData.location.Name);

    trackEvent("pageLoaded", { locationId, locationName });

    const code = getAttribute(
      locationData.location.Attributes,
      "Lead Booster Tracking Code"
    )?.value;

    if (code) {
      //Get All Scripts with regular expression
      const allScripts = [
        ...code.matchAll(/<script([^>]*)>([\s\S]*?)<\/script>/gi),
      ];

      console.log("allScripts", allScripts);
      /*
    Get data of each one scripts
    Positions 
    0: script value
    1: attrs
    2: content
    */
      const scriptData = allScripts.map((data) => ({
        attrs: data[1],
        content: data[2],
      }));

      setTrackingCode(scriptData);
    }
  }, [locationData]);

  console.log(locationData);

  return locationData.loading ? (
    <BullseyeLoader />
  ) : (
    <Track>
      <Helmet>
        {trackingCode.map((script, index) => {
          const attributes = script.attrs.split(/\s+/).reduce((acc, attr) => {
            const [key, value] = attr.split("=");
            if (key) acc[key] = value ? value.replace(/["']/g, "") : true;
            return acc;
          }, {});

          return script.content.trim() ? (
            <script key={index} type="text/javascript" {...attributes}>
              {script.content}
            </script>
          ) : (
            <script key={index} {...attributes}></script>
          );
        })}
      </Helmet>
      <title>{locationData.location.SEO.SEOTitle}</title>
      <style>
        {`.btn-secondary { background-color: ${colorSchema.buttonColors.backgroundColor}; 
          border-color: ${colorSchema.buttonColors.backgroundColor};  }
          .dynamicForm__formTitle { color: ${colorSchema.buttonColors.backgroundColor};} 
          `}
      </style>
      <div className="container">
        <div className="row">
          <div className="col-12 ">
            <Modal
              className="listMapLocator__leadModal"
              show={showLeadForm}
              backdrop="static"
              onHide={handleCloseLeadForm}
            >
              <Modal.Header
                closeButton={true}
                className="listMapLocator__leadModal-greyBk"
              >
                {getLocationInformation(locationData.location)}
              </Modal.Header>

              <Modal.Body>
                <div className="row listMapLocator__leadModal-bodyContainer">
                  <div className={"col-12"}>
                    <FormLead
                      compact={false}
                      formId={formIdModal}
                      locationIds={[locationData.location.Id]}
                      GoogleCaptchaKey={
                        process.env.REACT_APP_GOOGLE_CAPTCHA_KEY
                      }
                      clientId={process.env.REACT_APP_CLIENT_ID}
                      apiKey={process.env.REACT_APP_API_KEY}
                      hasContainerSetted={false}
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <div className="row">
          <div className="col-12 ">
            <nav className="navbar navbar-expand-md navbar-light">
              {getAttribute(locationData.location.Attributes, "Logo")
                ?.value && (
                <img
                  src={
                    getAttribute(locationData.location.Attributes, "Logo")
                      ?.value
                  }
                  height="55"
                  alt="Logo"
                ></img>
              )}
              <a className="navbar-brand" href="https://salesxceleration.com">
                <img src={salesXLogo} height="55" alt="Logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                onClick={handleToggle}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse${
                  isExpanded ? " show" : ""
                }`}
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a
                      style={{ cursor: "pointer" }}
                      className="nav-link"
                      onClick={() =>
                        ShowModal(process.env.REACT_APP_CONTACTUS_FORM)
                      }
                    >
                      Contact Me
                    </a>
                  </li>
                  {getAttribute(
                    locationData.location.Attributes,
                    "My Sales Blog link"
                  )?.value && (
                    <li className="nav-item">
                      <a
                        style={{ cursor: "pointer" }}
                        className="nav-link"
                        href={
                          getAttribute(
                            locationData.location.Attributes,
                            "My Sales Blog link"
                          )?.value
                        }
                        onClickCapture={() =>
                          trackEvent({
                            eventType: EVENT_LOG_TYPE.logSearch,
                            attributeId: getAttribute(
                              locationData.location.Attributes,
                              "My Sales Blog link"
                            )?.id,
                            attributeTypeId: AttributeType.Link, // Link
                            event: "click",
                            eventValue: getAttribute(
                              locationData.location.Attributes,
                              "My Sales Blog link"
                            )?.value,
                            responseType: SEARCH_RES_LOG_TYPE.attributeClicked,
                          })
                        }
                        target="_blank"
                      >
                        Blogs & Client Stories
                      </a>
                    </li>
                  )}

                  {getAttribute(
                    locationData.location.Attributes,
                    "Website Link"
                  )?.value && (
                    <li className="nav-item">
                      <a
                        style={{ cursor: "pointer" }}
                        className="nav-link"
                        href={
                          getAttribute(
                            locationData.location.Attributes,
                            "Website Link"
                          )?.value
                        }
                        onClickCapture={() =>
                          trackEvent({
                            eventType: EVENT_LOG_TYPE.logSearch,
                            attributeId: getAttribute(
                              locationData.location.Attributes,
                              "Website Link"
                            )?.id,
                            attributeTypeId: AttributeType.Link, // Link
                            event: "click",
                            eventValue: getAttribute(
                              locationData.location.Attributes,
                              "Website Link"
                            )?.value,
                            responseType: SEARCH_RES_LOG_TYPE.attributeClicked,
                          })
                        }
                        target="_blank"
                      >
                        {
                          getAttribute(
                            locationData.location.Attributes,
                            "Website Link"
                          )?.displayText
                        }
                      </a>
                    </li>
                  )}
                  {getAttribute(
                    locationData.location.Attributes,
                    "Optional Link"
                  )?.value && (
                    <li className="nav-item">
                      <a
                        style={{ cursor: "pointer" }}
                        className="nav-link"
                        href={
                          getAttribute(
                            locationData.location.Attributes,
                            "Optional Link"
                          )?.value
                        }
                        target="_blank"
                        onClickCapture={() =>
                          trackEvent({
                            eventType: EVENT_LOG_TYPE.logSearch,
                            attributeId: getAttribute(
                              locationData.location.Attributes,
                              "Optional Link"
                            )?.id,
                            attributeTypeId: AttributeType.Link, // Link
                            event: "click",
                            eventValue: getAttribute(
                              locationData.location.Attributes,
                              "Optional Link"
                            )?.value,
                            responseType: SEARCH_RES_LOG_TYPE.attributeClicked,
                          })
                        }
                      >
                        {
                          getAttribute(
                            locationData.location.Attributes,
                            "Optional Link"
                          )?.displayText
                        }
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-12 ">
            <div className="card shadow-sm p-0 mt-1">
              <Slider2
                title="READY TO DRIVE SALES REVENUE?"
                titleStyle={{ ...colorSchema.fontColor }}
                titleParagraph="I understand that leading and growing your company is hard work. Many business leaders don’t have the time, expertise, or energy it takes to put a sales strategy in place that will drive revenue for years to come."
                subTitleParagraph={`As an Outsourced VP of Sales, I am a <strong> Certified Sales Operating Management System<b>&trade;</b>  </strong> expert, ready to build a sales infrastructure for sustained growth. If you are ready to take the first step, contact me to set up a COMPLIMENTARY DISCOVERY CALL.  ${locationData.location.EmailAddress} or ${locationData.location.PhoneNumber}`}
                subTitle={`HI, I’M ${TitleName}, AND I CAN HELP.`}
                logo={locationData.location?.ImageFileUrl}
                linkedIn={{
                  link: getAttribute(
                    locationData.location.Attributes,
                    "LinkedIn Profile"
                  )?.value,
                  id: getAttribute(
                    locationData.location.Attributes,
                    "LinkedIn Profile"
                  )?.id,
                }}
                twitter={{
                  link: getAttribute(
                    locationData.location.Attributes,
                    "Twitter Profile"
                  )?.value,
                  id: getAttribute(
                    locationData.location.Attributes,
                    "Twitter Profile"
                  )?.id,
                }}
                facebook={{
                  link: getAttribute(
                    locationData.location.Attributes,
                    "Facebook Profile"
                  )?.value,
                  id: getAttribute(
                    locationData.location.Attributes,
                    "Facebook Profile"
                  )?.id,
                }}
                locationId={locationData.location.Id}
                locationName={locationData.location.Name}
              ></Slider2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card shadow-sm p-0 mt-1">
              <div className="row">
                <div className="col-md-12 p-3 mx-3">
                  <h2 style={{ ...colorSchema.fontColor }}>
                    ARE YOU EXPERIENCING ANY OF THESE SALES CHALLENGES?
                  </h2>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="px-3">
                      <ul>
                        <li>
                          <b>Growth:</b> Experiencing stagnant/declining sales,
                          or are you growing too quickly and can’t scale?
                        </li>
                        <li>
                          <b>Stress:</b> Doing too many things (and none as well
                          as you could), and frustrated by the lack of control
                          you feel?
                        </li>
                        <li>
                          <b>Balance:</b> Are you inundated with day-to-day
                          decisions, instead of focusing time on new ideas and
                          ways to drive the business forward?
                        </li>
                        <li>
                          <b>Plan:</b> Do you have a clear roadmap outlining how
                          to build a solid sales infrastructure?
                        </li>
                      </ul>
                      <div className="p-3 d-md-flex">
                        <div>
                          <p>
                            If you find yourself facing uncertainty about the
                            road ahead, you are not alone. As a highly
                            experienced sales leader, I specialize in assessing
                            your sales organization and implementing key
                            components of our{" "}
                            <b>
                              Certified Sales Operating Management System
                              <b>&trade;</b>
                            </b>{" "}
                            to elevate your sales performance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="d-flex justify-content-left flex-wrap">
                    {salesGrowthInformations.map((information, index) => {
                      return (
                        <CardWithImage
                          key={index}
                          image={information.img}
                          title={information.title}
                          body={information.body}
                          fontColor={colorSchema.fontColor}
                          templateStyle={2}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="px-3 mx-3 pt-3">
                  <h2 style={{ ...colorSchema.fontColor }}>
                    SOLUTIONS FOR SALES GROWTH:
                  </h2>
                </div>
                <div className="px-3 mx-3">
                  <p>
                    Our proven system, built from time-tested tools and
                    resources, is designed to cover every aspect of sales
                    development. Let’s discuss how I can help you build a
                    successful sales organization to meet your revenue goals.
                  </p>
                </div>
                <div className="col-md-12">
                  <div className="mb-3 px-3">
                    <a
                      className="btn btn-primary rounded-0 font-weight-bold mb-2"
                      style={{ ...colorSchema.buttonColors }}
                      href={`https://salesxceleration.com/sales-agility-assessment?locationid=${locationData.location.Id}`}
                      target="_blank"
                      rel="noreferrer"
                      id="btnTakeSalesASsessment"
                    >
                      Take Sales Assessment
                    </a>
                    {getAttribute(
                      locationData.location.Attributes,
                      "Calendar link"
                    )?.value && (
                      <a
                        className="btn btn-primary rounded-0 font-weight-bold mb-2"
                        style={{ ...colorSchema.buttonColors }}
                        href={
                          getAttribute(
                            locationData.location.Attributes,
                            "Calendar link"
                          )?.value
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        Schedule a Conversation
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {events.length > 0 && (
          <div className="row ">
            <div className="col-12 ">
              <div className="card shadow-sm p-0 mt-1 salesx__card-body--container">
                <div className="row">
                  <div className="col-12 text-center">
                    <h2
                      className="pl-3 pt-2"
                      style={{ ...colorSchema.fontColor }}
                    >
                      Events
                    </h2>
                  </div>
                  <div className="d-flex justify-content-left flex-wrap">
                    {events.slice(0, 3).map((event) => {
                      return (
                        <div className="ml-3" key={event.EventId}>
                          <div className="p-3">
                            <Events
                              eventList={[event]}
                              showDateLikeDescription={false}
                              cssColor={colorSchema.fontColor.color}
                            ></Events>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-12">
            <div className="card shadow-sm p-0 mt-1">
              <div className="row">
                {caseStudies.length > 0 && (
                  <div className="col-lg-5 mb-3 mb-lg-0">
                    <div className="p-3">
                      <h3 className="text-center p-1">Case studies</h3>
                      <div>
                        <SimpleListWithTitle
                          title=""
                          items={caseStudies}
                          icon={documentIcon}
                          containerCssClass="salesx__caseStudies--container"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={caseStudies.length > 0 ? "col-lg-7" : "col-lg-12"}
                >
                  <div className="pr-4 pt-3">
                    <h2
                      className="text-center"
                      style={{ ...colorSchema.fontColor }}
                    >
                      Why Sales Xceleration?
                    </h2>
                    <div className={caseStudies.length > 0 ? "pl-3" : "p-3"}>
                      <p>
                        Our Certified Sales Operating Management System™ is
                        designed to elevate sales performance through the core
                        elements of Strategy, Process, and Execution.
                      </p>
                      <p>
                        I begin by diving deep into your business and conducting
                        a comprehensive assessment to understand your unique
                        needs, allowing me to create a custom sales growth
                        strategy.
                      </p>
                      <p>
                        Then, I roll up my sleeves and get to work. From
                        refining existing processes to introducing new systems
                        and tools, I am dedicated to optimizing your sales
                        operation.
                      </p>
                      <p>
                        I won’t just offer advice and recommendations; I
                        actively participate in the execution,ensuring every
                        step is carried out effectively and efficiently to
                        deliver tangible results.
                      </p>
                      <p>
                        My goal is the same as yours, to achieve and exceed your
                        revenue goals. In fact, 98% of our clients see an
                        increase in revenue during the first year.
                      </p>
                      <div className="text-center text-md-left">
                        <button
                          className="btn rounded-0 px-4 font-weight-bold mr-5"
                          style={{ ...colorSchema.buttonColors }}
                          onClick={() =>
                            ShowModal(process.env.REACT_APP_CONTACTUS_FORM)
                          }
                        >
                          Contact Me to Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3 justify-content-center">
                {videos.slice(0, 3).map((video) => {
                  return (
                    <div className="col-lg-4 mb-3" key={video.id}>
                      <div className="pr-3 pl-3 pb-3">
                        {video.videourl.includes("iframe") ? (
                          <VideoPlayer
                            title=""
                            iframe={video.videourl}
                            titleCssClass=""
                            height={180}
                          />
                        ) : (
                          <VideoPlayer
                            title=""
                            videoList={[video.videourl]}
                            titleCssClass=""
                            height={180}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {salesBlog.length > 0 && (
          <div className="row">
            <div className="col-12 ">
              <div className="card shadow-sm p-3 mt-1 salesx__card-body--container">
                <h3 style={{ ...colorSchema.fontColor }}>Sales blogs</h3>
                <div className="row">
                  {salesBlog.slice(0, 3).map((blog) => {
                    return (
                      <div
                        className="col-sm-6 col-md-4 col-lg-3 mb-3 d-flex flex-column"
                        key={blog.Id}
                      >
                        <p className="strong">{blog.Title}</p>
                        {blog.BlogLink && (
                          <a
                            class="mt-auto"
                            href={blog.BlogLink}
                            style={{ ...colorSchema.fontColor }}
                            target="_blank"
                          >
                            Read more
                          </a>
                        )}
                      </div>
                    );
                  })}

                  <div className="col-sm-6 col-md-4 col-lg-3 mb-3 ml-auto">
                    <h4 className="strong">Want more insights?</h4>
                    <button
                      className="btn rounded-0 px-6 font-weight-bold mr-5"
                      style={{ ...colorSchema.buttonColors }}
                      onClick={() =>
                        ShowModal(process.env.REACT_APP_NEWSLETTER_FORM)
                      }
                    >
                      Receive Newsletter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(getAttribute(locationData.location.Attributes, "bio")?.value ||
          getOptionalServices(locationData.location.Attributes)?.length >
            0) && (
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="card shadow-sm p-3 mt-1">
                <div className="row">
                  <div className="col-12">
                    <div className="pt-3 pl-1 pr-3">
                      <h2 style={{ ...colorSchema.fontColor }}>
                        Face the future with confidence
                      </h2>
                      {getAttribute(locationData.location.Attributes, "bio")
                        ?.value && (
                        <div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: getAttribute(
                                locationData.location.Attributes,
                                "bio"
                              )?.value,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>

                    {getOptionalServices(locationData.location.Attributes)
                      ?.length > 0 && (
                      <div className="px-2">
                        <Showcase
                          title=""
                          items={getOptionalServices(
                            locationData.location.Attributes,
                            colorSchema.fontColor.color
                          )}
                          cssColor={colorSchema.fontColor.color}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {testimonials.length > 0 && (
          <div className="row mt-1">
            <div className="col-12 ">
              <div className="card shadow-sm">
                <div className="row g-3">
                  <div className="col-12 text-center pt-4">
                    <h2 style={{ ...colorSchema.fontColor }}>
                      What my clients say
                    </h2>
                  </div>
                  <div className="col-12">
                    <TestimonialsCarousel testimonials={testimonials} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {getAttribute(locationData.location.Attributes, "Promote Amplify")
          ?.value === "True" && (
          <div className="row">
            <div className="col-12 ">
              <div className="card shadow-sm p-0 mt-1">
                <div className="row">
                  <div className="col-md-7">
                    <div className="p-3">
                      <div className="d-flex">
                        <h2 className="mt-3 text-image justify-content-between">
                          RECRUITING SERVICES
                        </h2>
                        <img
                          src={amplifyLogo}
                          alt="amplify_logo final"
                          style={{ maxHeight: 75 }}
                          className="ml-auto mb-1 "
                        ></img>
                      </div>
                      <div>
                        <p>
                          Need help recruiting for a sales position in your
                          company? We have a unique perspective and a focused
                          specialty in sales recruiting.
                        </p>
                        <p>
                          Amplify Recruiting is Sales Xcelerations wholly-owned
                          subsidiary. Our 25+ years of experience, dedicated
                          recruiters, and proven 5-step Hiring Approach brings a
                          new level of recruiting support to our clients.
                        </p>
                      </div>
                      {getAttribute(
                        locationData.location.Attributes,
                        "Enable Hiring Assessment"
                      )?.value === "True" && (
                        <>
                          <div>
                            <p>
                              Do you have the right skillset to hire
                              top-performing candidates? Take our hiring
                              assessment to find out.
                            </p>
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn rounded-0 px-4 font-weight-bold mr-4 mb-2"
                              style={{
                                ...colorSchema.buttonColors,
                              }}
                              onClick={() =>
                                ShowModal(
                                  process.env.REACT_APP_CONTACTUS_AMPLIFY_FORM
                                )
                              }
                            >
                              Help Me Recruit
                            </button>
                            <a
                              className="btn rounded-0 px-4 font-weight-bold mb-2"
                              style={{ ...colorSchema.buttonColors }}
                              href={`https://www.amplifyrecruiting.com/saa-hiring-skills-assessment?locationid=${locationData.location.Id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Take Hiring Assessment
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="p-3">
                      <img
                        src={recruitingServiceImage}
                        className="img-fluid"
                        alt="Why Consultants Crisis"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {getAttribute(locationData.location.Attributes, "Promote CSL")
          ?.value === "True" && (
          <div className="row">
            <div className="col-12 ">
              <div className="card shadow-sm p-0 mt-1">
                <div className="row">
                  <div
                    className={
                      getAttribute(
                        locationData.location.Attributes,
                        "Promote Amplify"
                      )?.value === "True"
                        ? "col-12"
                        : "col-7"
                    }
                  >
                    <div className="px-3 pb-3">
                      <div className="d-flex align-items-center">
                        <h2 className="mr-4 justify-content-between text-image">
                          SALES LEADERSHIP TRAINING
                        </h2>
                        <img
                          src={
                            locationData.location.CountryCode === "CA"
                              ? CLSLogoCanada
                              : CSLLogo
                          }
                          alt="CSL_SX_logo"
                          style={{ maxHeight: 75 }}
                          className="mb-2 ml-4"
                        ></img>
                      </div>
                      <div>
                        <p>
                          If you are ready to expand your sales leadership
                          skills, our{" "}
                          {locationData.location.CountryCode === "CA"
                            ? "Accredited Sales Leader (ASM)"
                            : "Certified Sales Leader (CSL)"}{" "}
                          program is for you. The{" "}
                          {locationData.location.CountryCode === "CA"
                            ? "ASM"
                            : "CSL"}{" "}
                          designation is the country’s most comprehensive sales
                          leadership certification program offered, guaranteed
                          to prepare you with the analytical, tactical, and
                          strategic sales management skills needed to drive
                          revenue growth for your organization.
                        </p>
                        {/* 
                          <p>
                            Curious to see the gaps in your sales management
                            skills? Take my free Sales Leadership assessment to
                            learn where you may be able to improve.
                          </p>
                        */}
                      </div>
                      <div>
                        {/* 
                          <a
                            className="btn rounded-0 px-4 font-weight-bold mr-6"
                            style={{ ...colorSchema.buttonColors }}
                            href={`https://salesxceleration.com/sales-leadership-assessment?locationid=${locationData.location.Id}`}
                            onClickCapture={() =>
                              trackEvent({
                                eventType: EVENT_LOG_TYPE.logSearch,
                                attributeId: getAttribute(
                                  locationData.location.Attributes,
                                  "SLA link"
                                )?.id,
                                attributeTypeId: AttributeType.Link, // Link
                                event: "click",
                                eventValue: getAttribute(
                                  locationData.location.Attributes,
                                  "SLA link"
                                )?.value,
                                responseType:
                                  SEARCH_RES_LOG_TYPE.attributeClicked,
                              })
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            Sales Leadership Assessment
                          </a>
                        */}
                      </div>
                    </div>
                  </div>
                  {getAttribute(
                    locationData.location.Attributes,
                    "Promote Amplify"
                  )?.value === "False" && (
                    <div className="col-md-5">
                      <div className="p-3">
                        <img
                          src={recruitingServiceImage}
                          className="img-fluid"
                          alt="Why Consultants Crisis"
                        ></img>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {getAttribute(locationData.location.Attributes, "CSL class")?.value ===
          "True" && (
          <div className="row">
            <div className="col-12 ">
              <div className="card shadow-sm p-3 mt-1">
                <h2>sales leadership training guaranteed to pay for itself</h2>
                <div className="pr-4 text-justify-left">
                  <div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: getAttribute(
                          locationData.location.Attributes,
                          "CSL class Information"
                        )?.value,
                      }}
                    />
                    <a
                      href="https://salesxceleration.formstack.com/forms/csl_lead_gen_form"
                      target="_blank"
                    >
                      <span style={{ ...colorSchema.fontColor }}>
                        Learn More
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 ">
            <footer className="bgfooter-xcelaration text-white py-3">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-4 mb-3 d-flex text-center text-md-left">
                    <div className="col-12">
                      <img src={salesXLogoWhite} height="55" alt="Logo" />
                      <p>Inc. 5000 List 4 Years in a Row</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 d-flex">
                    <div className="col-12 text-center text-md-left">
                      <ul className="list-unstyled">
                        <li>
                          <a
                            href="https://salesxceleration.com/case-studies/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            SALES XCELERATION CASE STUDIES
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://salesxceleration.com/sales-tools/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            SALES XCELERATION TOOLS
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://salesxceleration.com/ebooks/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            SALES XCELERATION EBOOKS
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 text-center">
                    <p>
                      I look forward to learning more about your organization.
                    </p>
                    {getAttribute(
                      locationData.location.Attributes,
                      "Calendar link"
                    )?.value && (
                      <a
                        className="btn btn-primary rounded-0 px-4 font-weight-bold mr-6"
                        href={
                          getAttribute(
                            locationData.location.Attributes,
                            "Calendar link"
                          )?.value
                        }
                        onClickCapture={() =>
                          trackEvent({
                            eventType: EVENT_LOG_TYPE.logSearch,
                            attributeId: getAttribute(
                              locationData.location.Attributes,
                              "Calendar link"
                            )?.id,
                            attributeTypeId: AttributeType.Link, // Link
                            event: "click",
                            eventValue: getAttribute(
                              locationData.location.Attributes,
                              "Calendar link"
                            )?.value,
                            responseType: SEARCH_RES_LOG_TYPE.attributeClicked,
                          })
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ ...colorSchema.buttonColors }}
                      >
                        Schedule a Conversation
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </Track>
  );
}

export default SalesXPage;
